import React, { PureComponent, createRef } from 'react'
import classNames from 'classnames'
import Styled from 'styled-components'

import H4 from '../components/H4'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Layout from '../layouts/MainLayout'
import Meditator from '../components/Meditator'
import HideDesktop from '../components/HideDesktop'
import HideMobile from '../components/HideMobile'
import { ButtonWhite } from '../components/Button'

import theme from '../theme'
import HeroSrc from '../assets/Home-2.svg'
import CloudSrc from '../assets/Calque-236.png'

import BerylSrc from '../assets/experts/Beryl.png'
import ClaireSrc from '../assets/experts/Claire.png'
import CloeSrc from '../assets/experts/Cloé.png'
import CocoSrc from '../assets/experts/Coco.png'
import DamienSrc from '../assets/experts/Damien.png'
import DianeSrc from '../assets/experts/Diane.png'
import DominiqueSrc from '../assets/experts/Dominique.png'
import ElenaSrc from '../assets/experts/Elena.png'
import ElineSrc from '../assets/experts/Eline.png'
import FabriceSrc from '../assets/experts/Fabrice.png'
import InkenSrc from '../assets/experts/Inken.png'
import KaizerSrc from '../assets/experts/Kaizer.png'
import ColasSrc from '../assets/experts/Colas.jpg'
import ManuelaSrc from '../assets/experts/Manuela.png'
import MartinSrc from '../assets/experts/Martin.png'
import SebastienSrc from '../assets/experts/Sébastien.png'
import SopieSrc from '../assets/experts/Sopie.png'
import ThomasEmmanuelGerardSrc from '../assets/experts/Thomas-Emmanuel-Gerard.png'

const BadgeSection = Styled.section`
  z-index: 1;
  position: relative;
  padding-top: 90px;
  padding-bottom: 195px;
  padding-left: 120px;
  padding-right: 120px;
  margin-top: -${theme.radius.big}px;
  border-radius: ${theme.radius.big}px;
  background-color: ${theme.color.primary};

  @media (max-width: ${theme.breakpoint}px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: -175px
  }
`

const CloudBackground = Styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  position: absolute;
  background-size: 50%;
  background-position: -18% 84%;
  background-color: ${theme.color.alternativeWhite};

  @media (max-width: ${theme.breakpoint}px) {
    background-size: cover;
    background-position: center;
  }
`

const Meditators = Styled.div`
  display: grid;
  margin-top: 183px;
  grid-gap: 135px 25px;
  justify-content: center;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(3, minmax(30%, 384px));

  @media (max-width: ${theme.breakpoint}px) {
    overflow-x: hidden;
    display: none;
  }
`

const MeditatorsMobile = Styled.div`
  display: none;

  @media (max-width: ${theme.breakpoint}px) {
    display: block;
  }
`

const MeditatorsContainer = Styled.div`
  scroll-snap-type: x mandatory;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  margin-top: 90px;

  & > * {
    scroll-snap-align: center;
    scroll-snap-stop: normal;
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;

    &:last-child {
      margin-right: 30px;
    }

    &:first-child {
      margin-left: 30px;
    }
  }
`

const MeditatorImg = Styled.div`
  position: relative;
  width: 349px;
  max-width: 80vw;
  padding-top: 84%;
  background-size: cover;
  background-position: center;
`

const MeditatorDescription = Styled.div`
  padding: 0 15px;
`

const MeditatorContent = Styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity ${theme.transition};

  & > * {
    padding: 15px;
    font-size: 42px;
    white-space: pre-wrap;
    color: ${theme.color.alternativeWhite};
    font-family: ${theme.typography.gascogne};
  }

  &.hover {
    opacity: 1;
  }
`

const MeditatorReadMore = Styled.div`
  display: flex;
  margin-top: 130px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const Padding = Styled.div`
  padding-left: 50px;
  padding-right: 50px;
`

const data = [
  {
    image: MartinSrc,
    firstname: 'Martin',
    lastname: 'Aylward',
    description:
      "Martin Aylward enseigne la méditation depuis 25 ans. Tout jeune, il part en Asie étudier cette pratique pendant de longues années, avant de revenir en Occident pour transmettre ces enseignements au cœur de nos vies quotidiennes. Il a fondé le Moulin de Chaves, un centre dédié à la méditation en Dordogne. Recommandé par Jon Kabat-Zinn lui-même, il forme aussi régulièrement des instructeurs de <i>mindfulness</i>, au sein de son organisme le Mindfulness Training Institute (MTI)<br /><br />Pour Mind, il a créé les programmes : <i>Step 1, Step 2, Step 3, La Posture, Le Bonheur, Les Pensées, Le Sommeil, L’Incertitude</i>",
  },
  {
    image: DianeSrc,
    firstname: 'Diane',
    lastname: 'Taleb',
    description:
      "Après avoir travaillé dans l'univers des start-ups, Diane accompagne celles et ceux en quête de sens à se réapproprier leur chemin de vie en renouant avec leur sensibilité et leur sensorialité. Elle intervient en entreprise et auprès de tout un chacun pour réapprendre à redevenir conscient, présent, développer son intelligence émotionnelle et relationnelle, se déployer et ainsi contribuer à construire un monde plus humain. Ses pratiques piliers sont la méditation de pleine conscience, la danse, la communication non violente, et l'ennéagramme. <br /><br />Son fun fact : elle a fait une attaque de panique sur une piste verte en ski ⛷<br /><br />Elle a réalisé le programme <i>Le Travail</i> et contribue régulièrement aux méditations lives du matin",
  },
  {
    image: SopieSrc,
    firstname: 'Sophie',
    lastname: 'Faure',
    description:
      "Sophie Faure enseigne la méditation de plein conscience et propose des programmes sur l'alimentation, la parentalité et la réduction du stress. Convaincue que nous avons toutes les réponses en nous, elle partage ses connaissances et sa pratique pour prendre soin du vivant.<br /><br />Elle a réalisé pour Mind le programme <i>Mindful Eating</i>",
    sessions: [],
  },
  {
    image: ElenaSrc,
    firstname: 'Elena',
    lastname: 'Zahariev',
    description:
      "Elena enseigne la méditation de pleine conscience depuis 2016 à travers plusieurs formats allant de MBSR aux enseignements du Dharma. Installée à Tours, elle pratique l'Analyse Psycho-Organique, une psychothérapie intégrative corps-esprit. Elle aime écrire, traduire et transmettre la pratique méditative en poésie.<br /><br />Elle a réalisé les programmes <i>l’Ancrage</i> et <i>La liberté</i> et vous pouvez l’entendre régulièrement dans les lives du matin",
    sessions: [],
  },
  {
    image: InkenSrc,
    firstname: 'Inken',
    lastname: 'Dechow',
    description:
      "Inken enseigne la pleine conscience et l'autocompassion et accompagne particuliers et professionnels pour l'intégrer avec douceur et joie dans la vie quotidienne.<br /><br />Son credo : \"Laissons la beauté de ce que nous aimons, être ce que nous faisons\"<br /><br />Sinon, elle a une petite obsession de rangement, surtout quand elle est énervée ! 😉<br /><br />Elle a réalisé, avec son compagnon Dominique Retoux les programmes <i>Marcher en pleine conscience</i>, <i>l’Auto-compassion</i> et le programme <i>Summer Mind</i>",
    sessions: [],
  },
  {
    image: DominiqueSrc,
    firstname: 'Dominique',
    lastname: 'Retoux',
    description:
      "Instructeur de méditation de pleine conscience qu'il enseigne avec enthousiasme pour cultiver calme et sérénité au cour de nos vies frénétiques. Son credo : \"je vais bien le monde va mieux !\"<br /><br />Il a réalisé, avec sa compagne Inken Dechow les programmes <i>Marcher en pleine conscience</i>, l’<i>Auto-compassion</i> et le programme <i>Summer Mind</i>",
    sessions: [],
  },
  {
    image: CocoSrc,
    firstname: 'Coco',
    lastname: 'Brac de la Perrière',
    description:
      "Coach de dirigeants, organisatrice du grand diner en silence, maître de conférence à l'ESCP, conférencière sur la mindfulness et l'hyperconnexion, instructeur MBSR et teacher mindfulness en entreprise, Coco incarne cette voix vivante et spontanée qui manque parfois au monde de la méditation.<br /><br />Elle a réalisé les programmes <i>Le Silence</i> et <i>La Terre</i>",
    sessions: [],
  },
  {
    image: CloeSrc,
    firstname: 'Cloé',
    lastname: 'Brami',
    description:
      "Médecin cancérologue de formation, elle découvre la méditation de pleine conscience et le programme MBSR aux Etats-Unis en 2011. Faisant le lien entre le corps, les pensées et le cœur, elle continue de pratiquer, de transmettre aux jeunes soignants à l'université, en cancérologie et en privé dans des programmes MBSR ouverts à tous.<br /><br />Elle a réalisé le programme <i>Soignants</i>",
    sessions: [],
  },
  {
    image: DamienSrc,
    firstname: 'Damien',
    lastname: 'Desnos',
    description:
      "Après une dizaine d'années en tant que commercial dans des sociétés de conseil à Paris, Damien est parti vivre au Brésil et s'est formé à la pleine conscience au MTI de São Paulo et à l'Université de Médecine de San Diego en Californie. Il est de retour en France depuis 2022 où il travaille en tant qu'instructeur de pleine conscience en entreprises.<br /><br />Son fun fact : il est ventriloque et sort systématiquement son ami Georges le singe dès qu'il rencontre un enfant 🐒<br /><br />Il fait aussi partie des instructeurs qui vous accompagne régulièrement le matin.",
    sessions: [],
  },
  {
    image: FabriceSrc,
    firstname: 'Fabrice',
    lastname: 'Midal',
    description:
      "Fabrice s'est engagé depuis une vingtaine d'années à transmettre la pratique de la méditation. Expert reconnu, il la présente, au sein de l'Ecole Occidentale de Méditation, de manière bénévole. Soucieux de ne pas la voir réduite à une technique de bien-être, il en montre l'ampleur et la manière dont elle peut nous permettre de mieux envisager tant notre existence que les grands défis de notre société.<br /><br />Il a conçu pour Mind des séances guidées inédites proposées dans le programme sur <i>La Confiance</i>",
  },
  {
    image: BerylSrc,
    firstname: 'Beryl',
    lastname: 'Marjolin',
    description:
      "Formée instructrice MBSR au Center for Mindfulness de Jon Kabat-Zinn, Beryl enseigne le programme MBSR à des particuliers et intervient en entreprise.<br /><br />Beryl a conçu pour Mind le programme sur <i>Le Corps</i>",
  },
  {
    image: ThomasEmmanuelGerardSrc,
    firstname: 'Thomas-Emmanuel',
    lastname: 'Gérard',
    description:
      "Elève de Thich Nhat Hanh, formé au programme MBSR, Thomas a co-créé 'Mindful at Work' pour sensibiliser à la pleine conscience dans le cadre professionnel. Il est aussi facilitateur en intelligence collective et coach de dirigeants.<br /><br />Thomas a conçu pour Mind le programme sur l'<i>Authenticité</i>",
  },
  {
    image: SebastienSrc,
    firstname: "Sébastien",
    lastname: "Henry",
    description: "Un des pionniers en France de la pratique de la méditation en entreprise, il nourrit sa créativité pour inventer de nouvelles formes d'actions collectives pour la planète et les plus pauvres.<br /><br />Sébastien est l'auteur du programme sur <i>La créativité</i>",
  },
  {
    image: ClaireSrc,
    firstname: "Claire",
    lastname: "Mizzi",
    description: "Psychologue et psychothérapeute en libéral à Paris, elle a découvert le yoga et la méditation dans sa jeunesse.<br /><br />Accompagner des personnes en psychothérapie, méditer et transmettre cette pratique lui tient à cœur pour permettre de se tourner plus harmonieusement vers le monde.<br /><br />Devenue instructrice MBSR et MBCT, formée à la thérapie Fondée sur la Compassion, elle guide des groupes, des journées et des retraites de méditation, et enseigne la méditation à des professionnels de santé ainsi qu'à l'Université.<br /><br />Elle a réalisé le programme <i>Émotions difficiles</i>",
  },
  {
    image: ManuelaSrc,
    firstname: "Manuela",
    lastname: "Tomba",
    description: "Psychologue et psychothérapeute depuis plus de 20 ans, Manuela a exercé en hospitalier par le passé et est installée en libéral à Paris depuis de nombreuses années. En plus de sa pratique clinique, elle s'occupe de formation et de la supervision des professionnels de santé au protocole MBCT, la thérapie Cognitive basée sur la Pleine Conscience pour la prévention de la rechute dépressive.<br /><br />Avec ses compétences cliniques et une quinzaine d'années d'intégration de la psychothérapie à la pratique de la Pleine Conscience sous des différentes formes, Manuela accompagne pour renforcer les ressources intérieures et accueillir avec sagesse les difficultés de la vie.<br /><br />Elle a réalisé le programme <i>Équilibre psychologique</i>",
  },
  {
    image: ElineSrc,
    firstname: "Eline",
    lastname: "Snel",
    description: "Formatrice et thérapeute depuis plus de trente ans Eline Snel est instructrice MBSR et elle élabore dès 1990 une méthode de méditation pour les enfants, les adolescents et les adultes. Elle a créé l’Académie pour l’enseignement de la pleine conscience aux Pays-Bas, où des milliers d’enfants et d’adolescents ont suivi son programme. En France, plus de cent mille parents, enseignants et thérapeutes utilisent la « méthode de la grenouille » pour développer les capacités de concentration et la sérénité des enfants.<br /><br />Son ouvrage <i>Calme et attentif comme une grenouille</i> est un best-seller mondial traduit dans douze langues. Eline Snel a créé en exclusivité pour Mind 4 programmes pour les enfants.",
  },
  {
    image: KaizerSrc,
    firstname: "Susan",
    lastname: "Kaiser-Greenland",
    description: "Susan Kaiser-Greenland est l'une des pionnières de la méditation de pleine conscience pour les enfants. Elle enseigne son programme partout dans le monde. Elle nous propose 2 séances tirées du livre \"Un cœur tranquille et sage\" aux éditions Les Arènes.",
  },
  {
    image: ColasSrc,
    firstname: "Colas",
    lastname: "Zibaut",
    description: "Colas a été formé dans la tradition Vipassana. Après quelques années en tant que journaliste radio, sa pratique de méditation l’a poussée à changer de voie pour en faire un objet d’étude. Désormais chercheur en Sciences de l'Information et de la Communication, ses travaux portent sur la méditation et les nouvelles technologies. Pour Mind, il s’occupe de la R&D, et il est l'host des podcasts <i>Inspire</i> et <i>Loopings</i>.<br /><br />Son fun fact : il éternue en faisant des bruits de moine shaolin 🥷",
  },
];

class ExpertPage extends PureComponent {
  /* LIFECYCLE */

  /**
   * @constructor
   * @param {*} props - Properties of the PureComponent
   */
  constructor(props) {
    super(props)

    this.state = {
      current: {},
      activeIndex: 0,
      seeMore: true,
    }

    this.meditators = createRef()
  }

  /**
   * @componentDidMount
   */
  componentDidMount() {
    if (window.innerWidth < theme.breakpoint) {
      this.interval = window.setInterval(this.onTouchMove, 500)
    }
  }

  /**
   * @componentWillUnmount
   */
  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  /* METHODS */

  toggleSeeMore = () => this.setState({ seeMore: !this.state.seeMore })

  onTouchMove = () => {
    const { innerWidth } = window
    const { current: meditators } = this.meditators

    if (meditators) {
      const count = meditators.children.length
      const widthRef = meditators.children[0].clientWidth
      const activeIndex = Math.ceil(meditators.scrollLeft / (widthRef + 30))
      // const activeIndex = Math.floor(count * index)
      // console.log(widthRef, count, index, activeIndex)

      // console.log('--- index', index, data[index])
      this.setState({
        activeIndex,
        current: data[activeIndex],
      })
    }
  }

  /* RENDER */

  /**
   * @render
   * @returns {JSX}
   */
  render() {
    const { location } = this.props
    const { current, seeMore, activeIndex } = this.state

    return (
      <Layout location={location}>
        <SEO title="Les experts Mind" />

        <Hero
          src={HeroSrc}
          title="Les experts Mind"
          subtitle="Des instructeurs de pleine conscience"
          imgStyle={{ marginTop: 450 }}
          imgStyleMobile={{
            marginTop: -100,
            maxWidth: '60%',
            marginLeft: '40%',
            width: '384px',
          }}
          mask={
            <CloudBackground style={{ backgroundImage: `url(${CloudSrc}` }} />
          }
        />

        <BadgeSection id="about">
          <HideDesktop>
            <Padding>
              <H4>Les enseignants Mind</H4>
            </Padding>
          </HideDesktop>
          <HideMobile>
            <H4>
              Les séances guidées proposées sur Mind ont été créées par des
              enseignants reconnus dans le monde entier.
            </H4>
          </HideMobile>

          <Meditators>
            {(seeMore ? data : data.slice(0, 6)).map((item) => (
              <Meditator
                image={item.image}
                sessions={item.sessions}
                lastname={item.lastname}
                firstname={item.firstname}
                description={item.description}
                key={`${item.firstname}-${item.lastname}`}
              />
            ))}
          </Meditators>

          {!seeMore && (
            <MeditatorReadMore>
              <ButtonWhite onClick={this.toggleSeeMore}>Voir plus</ButtonWhite>
            </MeditatorReadMore>
          )}

          <MeditatorsMobile>
            <MeditatorsContainer ref={this.meditators}>
              {data.map((item, i) => (
                <MeditatorImg
                  style={{ backgroundImage: `url(${item.image})` }}
                  key={`${item.firstname}-${item.lastname}`}
                >
                  <MeditatorContent
                    className={classNames({ hover: activeIndex === i })}
                  >
                    <div>
                      {item.firstname}
                      <br />
                      {item.lastname}
                    </div>
                  </MeditatorContent>
                </MeditatorImg>
              ))}
            </MeditatorsContainer>

            <MeditatorDescription>
              {current && (
                <Meditator
                  sessions={current.sessions}
                  description={current.description}
                />
              )}
            </MeditatorDescription>
          </MeditatorsMobile>
        </BadgeSection>
      </Layout>
    )
  }
}

export default ExpertPage
