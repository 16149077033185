import React from 'react'
import Styled from 'styled-components'

import theme from '../theme'


const Container = Styled.div`
  width: 100%;
  max-width: 384px;

  @media (max-width: ${theme.breakpoint}px) {
    margin: 0 auto;
  }
`

const Img = Styled.img`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
`

const Name = Styled.p`
  margin-top: 45px;
  font-size: 33px;
  color: ${theme.color.alternativeWhite};
  font-family: ${theme.typography.gascogne};
`

const Description = Styled.p`
  opacity: 0.8;
  font-size: 16px;
  margin-top: 21px;
  color: ${theme.color.alternativeWhite};

  & > * {
    font-size: 16px;
  }

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 22px;

    & > * {
      font-size: 22px;
    }
  }
`

const SessionTitle = Styled.h5`
  font-size: 15px;
  margin-top: 23px;
  font-weight: bold;
  padding-bottom: 3px;
  letter-spacing: 2.97px;
  text-transform: uppercase;
  color: ${theme.color.alternativeWhite};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 22px;
    margin-top: 40px;
  }
`

const Session = Styled.p`
  font-size: 15px;
  margin-top: 8px;
  white-space: pre-line
  color: ${theme.color.alternativeWhite};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 18px;
  }
`

const Sessions = Styled.div`
  @media (max-width: ${theme.breakpoint}px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    & > * {
      width: calc(50% - 30px);
      margin-right: 30px;

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }
  }
`

const ImgContainer = Styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 143%;
  background-size: cover;
  background-position: center;
`


const Meditator = ({
  image,
  firstname,
  lastname,
  description,
  sessions,
}) => (
  <Container>
    {image && <ImgContainer style={{ backgroundImage: `url(${image})` }} />}
    {/* image && <Img src={image} alt={`${firstname} ${lastname}`} /> */}

    {(firstname || lastname) && <Name>{firstname}<br />{lastname}</Name>}
    <Description dangerouslySetInnerHTML={{ __html: description }} />

    {Array.isArray(sessions) && sessions.length > 0 && (
      <>
        <SessionTitle>Séances</SessionTitle>
        <Sessions>
          {sessions.map((session) => <Session key={session}>{session}</Session>)}
        </Sessions>
      </>
    )}
  </Container>
)


Meditator.defaultProps = {
  sessions: [],
}


export default Meditator
